<template>
  <v-dialog :max-width="big ? 1200 : 700" v-on:click:outside="close" v-model="dialog">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon router @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <slot></slot>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    big: Boolean,
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
